export const lineChartDataDashboard = [
  {
    name: "Aggregate (kT)",
    data: [500, 480, 300, 220, 510, 250, 290, 240, 320, 350, 270, 390],
  },
  {
    name: "Bitumen (kT)",
    data: [220, 10, 310, 360, 380, 430, 560, 360, 410, 520, 340, 560],
  },
  {
    name: "Fuel (kT)",
    data: [210, 10000, 310, 340, 360, 430, 540, 360, 410, 510, 340, 540],
  },
  {
    name: "Filler (kT)",
    data: [180, 230, 290, 340, 350, 400, 560, 340, 390, 490, 320, 570],
  },
  {
    name: "Additive (kT)",
    data: [190, 240, 300, 330, 360, 410, 570, 360, 380, 520, 310, 560],
  },
  {
    name: "RAP (kT)",
    data: [210, 230, 310, 340, 360, 420, 550, 340, 390, 510, 330, 550],
  },
  {
    name: "Gas (kT)",
    data: [220, 240, 320, 350, 370, 430, 560, 350, 400, 530, 340, 540],
  },
  {
    name: "Electricity (kWh)",
    data: [200, 220, 310, 340, 360, 420, 560, 340, 390, 500, 330, 560],
  }
];
