// VerticalStackedBarChart.js
import React from "react";
import ReactApexChart from "react-apexcharts";

const VerticalStackedBarChart = () => {
  const sampleData = {
    "Site 1": [50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160],
    "Site 2": [40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150],
    "Site 3": [30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140],
    "Site 4": [20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130],
    "Site 5": [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
    "Site 6": [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
  };

  const series = Object.entries(sampleData).map(([site, data]) => ({
    name: site,
    data: data,
  }));

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: { show: false },
    },
    colors: [
      "#14B8A6", "#3B82F6", "#6366F1", "#EC4899", "#F59E0B", "#FACC15"
    ],
    xaxis: {
      categories: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      labels: {
        style: {
          colors: 'white', // Set X-axis text color to white
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: 'white', // Set Y-axis text color to white
        },
      },
    },
    legend: {
      show: false,
      position: 'top',
    },
    dataLabels: {
        enabled: false, // Disable data labels on the bars
      },
  };

  return (
    <div>
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default VerticalStackedBarChart;