import React, { useState } from "react"; // Import useState for state management
import { CSVLink } from "react-csv"; // Import react-csv for CSV download functionality
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import VuiPagination from "components/VuiPagination"; // Import your custom VuiPagination
import Icon from "@mui/material/Icon"; // Import Icon for pagination arrows
import siteTableData from "layouts/data-export/data/siteTableData";

function DataExport() {
  const { columns: prCols, rows: prRows } = siteTableData;

  // Format data for CSV
  const csvData = prRows.map((row) => ({
    "Date Time": row["Date Time"].props.children.props.children,
    Asphalt: row["Asphalt"].props.children
      .map((child) => (child.props ? child.props.children : child))
      .join(" "),
    "RAP Tonnage": row["RAP Tonnage"].props.children
      .map((child) => (child.props ? child.props.children : child))
      .join(" "),
    "Annual Plant Status": row["Annual Plant Status"].props.children
      .map((child) => (child.props ? child.props.children : child))
      .join(" "),
    "Plant Idle Electricity": row["Plant Idle Electricity"].props.children,
    Electricity: row.Electricity.props.children,
    "Unused Agg": row["Unused Agg"].props.children,
    Gas: row.Gas.props.children,
    "Burner Idle Fuel": row["Burner Idle Fuel"].props.children,
    "Electricity Offline": row["Electricity Offline"].props.children,
    Aggregate: row.Aggregate.props.children,
    Bitumen: row.Bitumen.props.children,
    Fuel: row.Fuel.props.children,
    Additive: row.Additive.props.children,
    Filler: row.Filler.props.children,
  }));

  // State management for pagination
  const [page, setPage] = useState(1); // Change initial page to 1
  const [rowsPerPage] = useState(5); // Set default rows per page

  // Handle page change
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  // Calculate the current rows to be displayed based on pagination
  const displayedRows = prRows.slice((page - 1) * rowsPerPage, page * rowsPerPage); // Adjust for 1-based index

  // Calculate total pages
  const totalPages = Math.ceil(prRows.length / rowsPerPage);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Card>
          <VuiBox display="flex" justifyContent="space-between" alignItems="center">
            <VuiTypography variant="lg" color="white">
              Data Export
            </VuiTypography>
            {/* Add CSV download button */}
            <CSVLink
              data={csvData}
              filename={"site_table_data.csv"}
              style={{ textDecoration: "none" }}
            >
              <VuiButton
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#2196F3",
                  "&:hover": {
                    backgroundColor: "#008BB5", // Optional: Darker shade for hover effect
                  },
                }}
              >
                Download
              </VuiButton>
            </CSVLink>
          </VuiBox>
          <VuiBox
            sx={{
              "& th": {
                borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                  `${borderWidth[1]} solid ${grey[700]}`,
              },
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                    `${borderWidth[1]} solid ${grey[700]}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={displayedRows} /> {/* Use displayed rows for the table */}
          </VuiBox>
          {/*  Pagination  */}
          <VuiBox display="flex" justifyContent="center" p={2}>
            <VuiPagination>
              <VuiPagination item onClick={() => handleChangePage(page - 1)} disabled={page === 1}>
                <Icon>keyboard_arrow_left</Icon>
              </VuiPagination>
              {Array.from({ length: totalPages }, (_, index) => (
                <VuiPagination
                  key={index + 1}
                  item
                  active={page === index + 1}
                  onClick={() => handleChangePage(index + 1)}
                >
                  {index + 1}
                </VuiPagination>
              ))}
              <VuiPagination item onClick={() => handleChangePage(page + 1)} disabled={page === totalPages}>
                <Icon>keyboard_arrow_right</Icon>
              </VuiPagination>
            </VuiPagination>
          </VuiBox>
        </Card>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DataExport;