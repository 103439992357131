

/** 
  All of the routes are added here,
  You can add a new route, customize the routes and delete the routes here.

*/

//  React layouts
import Dashboard from "layouts/site-dashboard";
import DataExport from "layouts/data-export";
import Settings from "layouts/settings";
import AreaDashboard from "layouts/area-dashboard";
import SignIn from "layouts/authentication/sign-in";
import SignOut from "layouts/authentication/sign-out";
import SignUp from "layouts/authentication/sign-up";

//  React icons
import { IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { IoBuild } from "react-icons/io5";
import { BsCreditCardFill } from "react-icons/bs";
import { IoStatsChart } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import HomeIcon from '@mui/icons-material/Home';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from '@mui/icons-material/Settings';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
const routes = [
  {
    type: "collapse",
    name: "Site Dashboard",
    key: "site-dashboard",
    route: "/site-dashboard",
    icon: <HomeIcon size="15px" color="inherit" />,
    component: Dashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Data Export",
    key: "data-export",
    route: "/data-export",
    icon: <StorageIcon size="15px" color="inherit" />,
    component: DataExport,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Area Dashboard",
    key: "AreaDashboard",
    route: "/area-dashboard",
    icon: <CalendarViewMonthIcon size="15px" color="inherit" />,
    component: AreaDashboard,
    noCollapse: true,
  },
  
  { type: "title", title: "Account Pages", key: "account-pages" },

  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: <SettingsIcon size="15px" color="inherit" />,
    component: Settings,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-out",
    route: "/sign-out",
    icon: <LoginIcon size="15px" color="inherit" />,
    component: SignOut,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/sign-in",
    icon: <LoginIcon size="15px" color="inherit" />,
    component: SignIn,
    noCollapse: true,
  }

];

export default routes;
