// @mui/material components
import { Card } from "@mui/material";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend, Title);

// Sample data (to be replaced with API data later)
const siteData = [
  { name: "Site 1", avgAsphalt: 120, color: "#14B8A6" },
  { name: "Site 2", avgAsphalt: 150, color: "#3B82F6" },
  { name: "Site 3", avgAsphalt: 80, color: "#6366F1" },
  { name: "Site 4", avgAsphalt: 95, color: "#EC4899" },
  { name: "Site 5", avgAsphalt: 110, color: "#F59E0B" },
  { name: "Site 6", avgAsphalt: 130, color: "#FACC15" },
];

// Calculate total asphalt for percentage calculations
const totalAsphalt = siteData.reduce((acc, site) => acc + site.avgAsphalt, 0);

// Data for the Pie chart
const data = {
  labels: siteData.map(site => site.name),
  datasets: [{
    data: siteData.map(site => site.avgAsphalt),
    backgroundColor: siteData.map(site => site.color),
    hoverBackgroundColor: siteData.map(site => site.color),
    borderColor: '#ffffff',
    borderWidth: 2,
    cutout: '70%'  // Create donut effect
  }]
};

// Pie chart options
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },  // Disable default legend
    tooltip: {
      callbacks: {
        label: function (context) {
          const value = context.raw;
          const percentage = ((value / totalAsphalt) * 100).toFixed(2);
          return `${context.label}: ${value} tons (${percentage}%)`;
        }
      }
    }
  }
};

const PieChart = () => {
  return (
    <Card sx={{ padding: 2, height: '100%' }}>
      <VuiBox display="flex" flexDirection="column" justifyContent="space-between" height="100%">
        <VuiTypography variant="h6" fontWeight="bold" color="white" mb={2}>Asphalt</VuiTypography>

        <VuiBox display="flex" justifyContent="space-between" alignItems="center" sx={{ flexGrow: 1 }}>
          {/* Pie Chart */}
          <VuiBox sx={{ position: 'relative', width: '45%', height: '300px' }}>
            <Pie data={data} options={options} />
          </VuiBox>

          {/* Index Table */}
          <VuiBox display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" sx={{ width: '45%' }}>
            {siteData.map((site, index) => (
              <VuiBox display="flex" alignItems="center" mb={1} key={index}>
                <VuiBox
                  sx={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: site.color,
                    mr: 2,
                  }}
                />
                <VuiTypography variant="caption" color="white">
                  {site.name}: {site.avgAsphalt} tons ({((site.avgAsphalt / totalAsphalt) * 100).toFixed(2)}%)
                </VuiTypography>
              </VuiBox>
            ))}
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default PieChart;