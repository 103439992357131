export const groupBarChartData = {
    series: [
      {
        name: "Asphalt",
        data: [40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150],
      },
      {
        name: "RAP",
        data: [30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140],
      },
      {
        name: "Fuel",
        data: [20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130],
      },
      {
        name: "Virgin",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
      },
    ],
  };