import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

//  React components
import VuiBox from "components/VuiBox";

//  React context
import { useVisionUIController, setLayout } from "context";

function DashboardLayout({ children }) {
  const [, dispatch] = useVisionUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "site-dashboard");
  }, [pathname]);

  return (
    <VuiBox
      sx={{
        display: "flex",       // Ensures children are laid out in a flexible container
        flexDirection: "column", // Stack children vertically
        minHeight: "100vh",    // Full viewport height
        p: 3,
        position: "relative",
        marginLeft: 0, // Ensure no margin for sidenav
        zIndex: 1,     // Make sure the layout is above any other potential components
      }}
    >
      {children}
    </VuiBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;