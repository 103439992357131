// @mui material components
import Grid from "@mui/material/Grid";
import { Card, Box, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import React, { useState, useEffect } from 'react';

//  React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProductionCardRAP from "layouts/site-dashboard/components/ProductionCardRAP";
import ProductionCardAsp from "layouts/site-dashboard/components/ProductionCardAsp";
import AnnualPlantStatus from "layouts/site-dashboard/components/AnnualPlantStatus";
import LivePlantStatus from "layouts/site-dashboard/components/LivePlantStatus";
import SiteInformation from "layouts/site-dashboard/components/SiteInformation";
import UsageCard from "layouts/site-dashboard/components/UsageCard";
import StockedMaterials from "layouts/site-dashboard/components/StockedMaterials";
import ConditionMonitoring from "layouts/site-dashboard/components/ConditionMonitoring";
import sharkImage from "assets/images/shark.png";

// Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import { lineChartDataDashboard } from "layouts/site-dashboard/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/site-dashboard/data/lineChartOptions";
import useAuth from "../../useAuth"; // Import the custom hook
import { useParams } from "react-router-dom";

function Dashboard() {
  useAuth(); // Handle token expiration
  const { site_id } = useParams(); // Dynamically extract `site_id` from the route
  const [siteData, setSiteData] = useState(null);

  // Fetch site data
  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/profile?site_id=${site_id}`
        );
        const data = await response.json();
        setSiteData(data);
      } catch (error) {
        console.error("Error fetching site data:", error.message);
      }
    };

    fetchSiteData();
  }, [site_id]);

  const updateProductionTarget = async (updatedData) => {
    try {
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/update",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ site_id, ...updatedData }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update production target");
      }

      const updatedSiteData = await response.json();
      setSiteData(updatedSiteData);
    } catch (error) {
      console.error("Error updating production target:", error.message);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={1}>
        <VuiBox mb={1}>
          <Grid container spacing={1}>
            {/* Site Information Component */}
            <Grid item xs={12} sm={6} md={3}>
            <SiteInformation siteId={site_id} siteData={siteData} />
            </Grid>
            {/* Annual Plant Status Component */}
            <Grid item xs={12} sm={6} md={3}>
              <AnnualPlantStatus />
            </Grid>
            {/* Live Plant Status Component */}
            <Grid item xs={12} sm={6} md={3}>
              <LivePlantStatus plantState="idle" />
            </Grid>
            {/* Shark Image Card */}
            <Grid item xs={12} sm={6} md={3}>
              <Card
                sx={{
                  height: "100%",
                  position: "relative",
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Link
                  href="https://sharkesms.com/"
                  target="_blank"
                  rel="noopener"
                  sx={{ display: "block", width: "100%", height: "100%" }}
                >
                  <VuiBox
                    sx={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={sharkImage}
                      alt="Shark"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        filter: "brightness(50%)", // Optional: adjust brightness for tinting
                      }}
                    />
                    <VuiBox
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)", // Overlay color
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1, // Ensure text is above the image
                      }}
                    >
                      <VuiTypography variant="h6" color="white" fontWeight="bold">
                        Visit Shark EMS
                      </VuiTypography>
                    </VuiBox>
                  </VuiBox>
                </Link>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>

        {/* Production Cards Section */}
        <VuiBox mb={1}>
          <Grid container spacing={1}>
            {/* Asphalt Production Card */}
            <Grid item xs={12} md={6}>
              <ProductionCardAsp
                title="Asphalt"
                dayProgress={0.5} // Day progress value 
                weekProgress={1} // Week progress value 
                monthProgress={2} // Month progress value 
                yearProgress={130000} // Year progress value 
                color="#0075FF"
                unit="T"
                site_id={site_id}  // Pass the site_id as a prop  
              />
            </Grid>

            {/* RAP Production Card */}
            <Grid item xs={12} md={6}>
              <ProductionCardRAP
                title="RAP"
                dayProgress={7.5}
                weekProgress={4.0}
                monthProgress={2.5}
                yearProgress={6.5}
                color="#D5ED9F"
                unit="T"
                maxDayValue={10}
                maxWeekValue={10}
                maxMonthValue={10}
                maxYearValue={10}
                
              />
            </Grid>
          </Grid>
        </VuiBox>

        <VuiBox mb={1}>
          <Grid container spacing={1}>
            {/* Usage live data */}
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UsageCard
                title="Unused Agg."
                color="#5E5CE6"
                value={7.0}
                maxValue={10}
                unit="T / yr"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UsageCard title="Gas" color="#73CAC5" value={3.0} maxValue={10} unit="kWh" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UsageCard
                title="Burner Idle Fuel"
                color="#C63C51"
                value={5.0}
                maxValue={10}
                unit="kWh"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UsageCard title="Fuel" color="#CAB773" value={2.0} maxValue={10} unit="kWh" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UsageCard
                title="Electricity Offline"
                color="#F0A8D0"
                value={2.0}
                maxValue={10}
                unit="kWh"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <UsageCard title="Electricity" color="#F0F477" value={6.5} maxValue={10} unit="kWh" />
            </Grid>
          </Grid>
        </VuiBox>

        {/* Line Chart Section */}
        <VuiBox mb={1}>
          <Grid container spacing={1}>
            {/* Annual Totalisers */}
            <Grid item xs={12} lg={12} xl={6}>
              <Card sx={{ height: "100%" }}>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography
                    variant="lg"
                    color="white"
                    fontWeight="bold"
                    sx={{
                      mb: 1,
                      fontSize: { xs: "1.25rem", sm: "1.5rem" },
                    }}
                  >
                    Annual Totalisers
                  </VuiTypography>
                  <VuiBox display="flex" alignItems="center" mb="40px">
                    <VuiTypography variant="button" color="success" fontWeight="bold">
                      +5% more{" "}
                      <VuiTypography variant="button" color="text" fontWeight="regular">
                        in 2024
                      </VuiTypography>
                    </VuiTypography>
                  </VuiBox>
                  <VuiBox sx={{ height: "100%" }}>
                    <LineChart
                      lineChartData={lineChartDataDashboard}
                      lineChartOptions={lineChartOptionsDashboard}
                    />
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>

            {/* Condition Monitoring */}
            <Grid item xs={12} lg={12} xl={3} sx={{ minHeight: "350px" }}>
              <ConditionMonitoring />
            </Grid>
            {/* Stocked Materials */}
            <Grid item xs={12} lg={12} xl={3} sx={{ minHeight: "350px" }}>
              <StockedMaterials />
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;