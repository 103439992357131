import { useState, useEffect } from "react";
import { jwtDecode } from 'jwt-decode';
import { Link } from "react-router-dom";

// React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "examples/GradientBorder";

// Assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgSignIn from "assets/images/BackGround.png";

import axios from "axios";
import { useNavigate } from "react-router-dom"; // To redirect after login

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook to redirect

  // Check if the user is already logged in (valid token in localStorage)
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        if (decodedToken.exp > currentTime) {
          // Redirect to dashboard if token is still valid
          navigate("/site-dashboard");
        }
      } catch (err) {
        // Token is invalid or expired, let the user log in
        localStorage.removeItem("token");
        localStorage.removeItem("email");
      }
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/login", { email, password });
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem('email', email);
        localStorage.setItem('user_id', response.data.user_id);
        navigate("/dashboard"); // Redirect to dashboard on successful login
      }
    } catch (err) {
      setError("Invalid login credentials");
    }
  };

  return (
    <CoverLayout title="Nice to see you again!" color="white" image={bgSignIn}>
      <VuiBox component="form" role="form" onSubmit={handleLogin}>
        <VuiBox mb={2}>
          <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            Email
          </VuiTypography>
          <GradientBorder minWidth="100%" padding="1px">
            <VuiInput
              type="email"
              placeholder="Your email..."
              fontWeight="500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            Password
          </VuiTypography>
          <GradientBorder minWidth="100%" padding="1px">
            <VuiInput
              type="password"
              placeholder="Your password..."
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        {error && <VuiTypography color="error">{error}</VuiTypography>}
        <VuiBox mt={4} mb={1}>
          <VuiButton type="submit" color="info" fullWidth>
            SIGN IN
          </VuiButton>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

export default SignIn;