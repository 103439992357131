// AreaDashboard.js
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import PieChart from "layouts/area-dashboard/components/Totals/PieChart"; // Import the pie chart component
import VerticalStackedBarChart from "layouts/area-dashboard/components/Totals/VerticalStackedBarChart"; // Import the stacked bar chart component
import DynamicSiteCard from "layouts/area-dashboard/components/SiteCard"; // Import the DynamicSiteCard
import useAuth from "../../useAuth"; // Import the custom hook
import axios from "axios";

// Virgin Avg Data
import LineChart from "examples/Charts/LineCharts/LineChart";
import { lineChartDataDashboard } from "layouts/area-dashboard/data/lineChartData";
import { lineChartOptionsDashboard } from "layouts/area-dashboard/data/lineChartOptions";
function AreaDashboard() {
  useAuth(); // This will handle token expiration check on page load

  const [userSites, setUserSites] = useState([]);
  const userToken = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchSitesData = async () => {
      try {
        const response = await axios.get(
          "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/sites",
          {
            headers: { Authorization: `${userToken}`, "Content-Type": "application/json" },
            params: { user_id: userId }, // Replace with the actual user ID
          }
        );

        // Mapping the fetched sites data to maintain the same structure as userSites
        const mappedSites = response.data.map((site, index) => ({
          id: index + 1, // Generate a unique ID based on index
          name: site.name,
          avgAsphalt: Math.floor(Math.random() * 11), // Random number between 0 and 10
          avgRap: Math.floor(Math.random() * 11), // Random number between 0 and 10
          avgFuel: Math.floor(Math.random() * 11), // Random number between 0 and 10
          avgVirgin: Math.floor(Math.random() * 11), // Random number between 0 and 10
          electricityUsage: Math.floor(Math.random() * 11), // Random number between 0 and 10
          fuelUsage: Math.floor(Math.random() * 11), // Random number between 0 and 10
          unusedAgg: Math.floor(Math.random() * 11), // Random number between 0 and 10
        }));

        setUserSites(mappedSites);
      } catch (error) {
        console.error("Error fetching user sites:", error.response || error.message);
      }
    };

    fetchSitesData();
  }, [userToken]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <VuiBox py={1}>
        {/* First Row - Totals Card with Pie Chart, Card 2 with Bar Chart */}
        <Grid container spacing={1}>
          {/* Totals Card */}
          <Grid item xs={12}>
            <Card>
              <VuiBox p={1}>
                {" "}
                {/* Reduced padding here */}
                <VuiTypography variant="h4" color="white" mb={1}>
                  Totals
                </VuiTypography>
                <Grid container spacing={1}>
                  {/* Pie Chart */}
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: "100%" }}>
                      <VuiBox
                        p={1} // Reduced padding
                        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                      >
                        <PieChart data={userSites} style={{ flexGrow: 1 }} />
                      </VuiBox>
                    </Card>
                  </Grid>

                  {/* Card 2 - Stacked Bar Chart */}
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: "100%" }}>
                      <VuiBox
                        p={1} // Reduced padding
                        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                      >
                        <VuiTypography variant="h6" color="white">
                          RAP
                        </VuiTypography>
                        <VerticalStackedBarChart data={userSites} />
                      </VuiBox>
                    </Card>
                  </Grid>

                  {/* Card 3 - Line Chart */}
                  <Grid item xs={12} md={4}>
                    <Card sx={{ height: "100%" }}>
                      <VuiBox
                        p={1} // Reduced padding
                        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
                      >
                        <VuiTypography variant="h6" color="white">
                          Virgin
                        </VuiTypography>
                        <LineChart
                          lineChartData={lineChartDataDashboard}
                          lineChartOptions={lineChartOptionsDashboard}
                        />
                      </VuiBox>
                    </Card>
                  </Grid>
                </Grid>
              </VuiBox>
            </Card>
          </Grid>
        </Grid>

        {/* Second Row - Dynamic Site Cards */}
        <VuiBox mt={1}>
          <Grid container spacing={1}>
            {userSites.map((site) => (
              <Grid item xs={12} sm={6} md={4} key={site.id}>
                <DynamicSiteCard site={site} />
              </Grid>
            ))}
          </Grid>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AreaDashboard;
