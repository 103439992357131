import React from 'react';
import { Card, useMediaQuery, useTheme } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import CmLineGraph from "./CmLineGraph"; // Adjust the import path as necessary

const ConditionMonitoring = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Sample data for each graph
    const gearboxVibrationData = [60, 35, 40, 45, 45, 45, 40, 45, 30, 25, 20, 10,15];
    const gearboxTemperatureData = [20, 25, 30, 35, 45, 40, 40, 45, 45, 45, 60, 60,65];
    const motorVibrationData = [10, 15, 25, 30, 35, 40, 50, 60, 70, 75, 80, 85,80];
    const motorTemperatureData = [20, 35, 40, 55, 60, 65, 80, 85, 90, 95, 100, 80,90];
    const motorOverCurrentData = [5, 10, 15, 20, 30, 40, 50, 65, 70, 80, 90, 95,95];

    const statusStyle = (status) => ({
        backgroundColor: status === 'Healthy' ? 'green' : status === 'Warning' ? '#ffb800' : '#ff0000',
        padding: '2px 6px',
        borderRadius: '4px',
        display: 'inline-block',
        color: 'white',
        fontSize: '0.75rem',
        whiteSpace: 'nowrap',
    });

    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative', padding: 2 }}>
            <VuiBox
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                <VuiTypography
                    variant="lg"
                    color="white"
                    fontWeight="bold"
                    sx={{
                        mb: 1,
                        fontSize: { xs: '1.25rem', sm: '1.5rem' },
                    }}
                >
                    Condition Monitoring
                </VuiTypography>

                <VuiBox
                    sx={{
                        marginTop: '40px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 1,
                        flexGrow: 1,
                        flexDirection: isSmallScreen ? 'column' : 'row',
                        overflowY: 'auto',
                        paddingBottom: 1,
                    }}
                >
                    {/* First Column: Gearboxes */}
                    <VuiBox sx={{ flex: 1, padding: 1, borderRadius: 2, minWidth: 0 }}>
                        <VuiTypography
                            variant="h6"
                            color="white"
                            gutterBottom
                            sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
                        >
                            Gearboxes
                        </VuiTypography>
                        <VuiBox sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                                <VuiTypography color="white">Vibration:</VuiTypography>
                                <VuiTypography color="white" sx={statusStyle(getLastStatus(gearboxVibrationData))}>
                                    {getLastStatus(gearboxVibrationData)}
                                </VuiTypography>
                            </VuiBox>
                            <CmLineGraph title="Gearboxes - Vibration" data={gearboxVibrationData} />
                            <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                                <VuiTypography color="white">Temperature:</VuiTypography>
                                <VuiTypography color="white" sx={statusStyle(getLastStatus(gearboxTemperatureData))}>
                                    {getLastStatus(gearboxTemperatureData)}
                                </VuiTypography>
                            </VuiBox>
                            <CmLineGraph title="Gearboxes - Temperature" data={gearboxTemperatureData} />
                        </VuiBox>
                    </VuiBox>

                    {/* Second Column: Motors */}
                    <VuiBox sx={{ flex: 1, padding: 1, borderRadius: 2, minWidth: 0 }}>
                        <VuiTypography
                            variant="h6"
                            color="white"
                            gutterBottom
                            sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
                        >
                            Motors
                        </VuiTypography>
                        <VuiBox sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                                <VuiTypography color="white">Vibration:</VuiTypography>
                                <VuiTypography color="white" sx={statusStyle(getLastStatus(motorVibrationData))}>
                                    {getLastStatus(motorVibrationData)}
                                </VuiTypography>
                            </VuiBox>
                            <CmLineGraph title="Motors - Vibration" data={motorVibrationData} />
                            <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                                <VuiTypography color="white">Temperature:</VuiTypography>
                                <VuiTypography color="white" sx={statusStyle(getLastStatus(motorTemperatureData))}>
                                    {getLastStatus(motorTemperatureData)}
                                </VuiTypography>
                            </VuiBox>
                            <CmLineGraph title="Motors - Temperature" data={motorTemperatureData} />
                            <VuiBox display="flex" justifyContent="space-between" alignItems="center">
                                <VuiTypography color="white">Over Current:</VuiTypography>
                                <VuiTypography color="white" sx={statusStyle(getLastStatus(motorOverCurrentData))}>
                                    {getLastStatus(motorOverCurrentData)}
                                </VuiTypography>
                            </VuiBox>
                            <CmLineGraph title="Motors - Over Current" data={motorOverCurrentData} />
                        </VuiBox>
                    </VuiBox>
                </VuiBox>
            </VuiBox>
        </Card>
    );
};

// Function to determine the last status
const getLastStatus = (data) => {
    const lastValue = data[data.length - 1];
    if (lastValue > 50) {
        return lastValue > 50 && data.slice(-5).filter(value => value > 50).length > 4 ? 'Alarm' : 'Warning';
    }
    return 'Healthy';
};

export default ConditionMonitoring;