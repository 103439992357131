import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import VuiTypography from "components/VuiTypography";

const CircularProgressWithCircle = ({ progress, title, color, size }) => {
  const filledSize = size - 20; // Adjust the size of the filled inner circle based on the outer size
  const filledColor = color; // Color for the filled circle
  const fontSize = Math.max(size / 8, 12); // Minimum font size is 12px

  return (
    <Box
      position="relative"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      width={size}
      height={size}
    >
      {/* Filled Circle */}
      <Box
        sx={{
          position: 'absolute',
          borderRadius: '50%',
          backgroundColor: filledColor,
          width: filledSize,
          height: filledSize,
        }}
      />
      {/* Circular Progress (around the filled circle) */}
      <CircularProgress
        variant="determinate"
        value={progress}
        size={size}
        thickness={1} // Set thickness of the progress bar to 3
        sx={{
          color: color,
          position: 'absolute',
        }}
      />
      {/* Title and Percentage Text */}
      <VuiTypography
        variant="body2"
        component="div"
        color="white"
        sx={{
          position: 'absolute',
          textAlign: 'center',
          fontSize: fontSize,
        }}
      >
        {title}
        <br />
        {`${Math.round(progress)}%`}
      </VuiTypography>
    </Box>
  );
};

export default CircularProgressWithCircle;