import React from "react";
import { Card, useMediaQuery, useTheme } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import SemiCircleProgress from "./SemiCircleProgress";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";

const ProductionCardRAP = ({
  title,
  dayProgress,
  weekProgress,
  monthProgress,
  yearProgress,
  color,
  unit,
  maxDayValue = 10,
  maxWeekValue = 10,
  maxMonthValue = 10,
  maxYearValue = 10,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const calculatePercentage = (value, maxValue) => (value / maxValue) * 100;
  const { gradients } = colors;
  const { cardContent } = gradients;

  const InfoBox = ({ progress, label, sx }) => (
    <VuiBox
      sx={{
        width: isSmallScreen ? '150px' : '250px',
        padding: isSmallScreen ? '10px 14px' : '16px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        borderRadius: '20px',
        background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
        ...sx,
      }}
    >
      <VuiTypography color="text" variant="caption" display="inline-block">
        0%
      </VuiTypography>
      <VuiBox
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ minWidth: isSmallScreen ? '50px' : '70px' }}
      >
        <VuiTypography color='white' variant='h3' sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>
          {progress} {unit}
        </VuiTypography>
        <VuiTypography color="text" variant="caption" display="inline-block">
          {label}
        </VuiTypography>
      </VuiBox>
      <VuiTypography color="text" variant="caption" display="inline-block">
        100%
      </VuiTypography>
    </VuiBox>
  );

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', padding: 1, paddingBottom: 0 }}>
      <VuiBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        p={2}
        sx={{ paddingBottom: 0 }}
      >
        <VuiTypography
          variant="lg"
          color="white"
          fontWeight="bold"
          sx={{
            mb: 1,
            fontSize: isSmallScreen ? '1.25rem' : isMediumScreen ? '1.5rem' : '1.75rem',
          }}
        >
          {title}
        </VuiTypography>

        <VuiBox
          display="flex"
          flexDirection={isSmallScreen ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
          gap={isSmallScreen ? 2 : 3}
          sx={{ paddingBottom: 0 }}
        >
          {/* Day Progress */}
          <VuiBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flex: '1 0 auto',
              maxWidth: isSmallScreen ? '250px' : '350px',
            }}
          >
            <VuiBox sx={{ display: "inline-flex" }}>
              <SemiCircleProgress
                percentage={calculatePercentage(dayProgress, maxDayValue)}
                size={{
                  width: isSmallScreen ? 220 : 270,
                  height: isSmallScreen ? 220 : 270,
                }}
                strokeWidth={10}
                strokeColor={color}
              />
            </VuiBox>
            <InfoBox
              progress={dayProgress}
              label="Day"
              sx={{
                minWidth: isSmallScreen ? '200px' : '320px',
                marginTop: isSmallScreen ? '-60px' : '-90px',
              }}
            />
          </VuiBox>

          {/* Triangular Layout: Week, Month, Year Progress */}
          <VuiBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexGrow: 1,
              justifyContent: 'center',
              paddingBottom: isSmallScreen ? '30px' : '50px',
            }}
          >
            {/* Week Progress */}
            <VuiBox
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flexGrow: 1,
                width: '100%',
              }}
            >
              <SemiCircleProgress
                percentage={calculatePercentage(weekProgress, maxWeekValue)}
                size={{
                  width: isSmallScreen ? 150 : 180,
                  height: isSmallScreen ? 150 : 180,
                }}
                strokeWidth={10}
                strokeColor={color}
              />
              <InfoBox
                progress={weekProgress}
                label="Week"
                sx={{ marginTop: isSmallScreen ? '-60px' : '-75px' }}
              />
            </VuiBox>

            {/* Month and Year Progress */}
            <VuiBox
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: isSmallScreen ? '10px' : '16px',
                width: '100%',
                flexShrink: 0,
              }}
            >
              <VuiBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <SemiCircleProgress
                  percentage={calculatePercentage(monthProgress, maxMonthValue)}
                  size={{
                    width: isSmallScreen ? 150 : 180,
                    height: isSmallScreen ? 150 : 180,
                  }}
                  strokeWidth={10}
                  strokeColor={color}
                />
                <InfoBox
                  progress={monthProgress}
                  label="Month"
                  sx={{ marginTop: isSmallScreen ? '-60px' : '-75px' }}
                />
              </VuiBox>
              <VuiBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <SemiCircleProgress
                  percentage={calculatePercentage(yearProgress, maxYearValue)}
                  size={{
                    width: isSmallScreen ? 150 : 180,
                    height: isSmallScreen ? 150 : 180,
                  }}
                  strokeWidth={10}
                  strokeColor={color}
                />
                <InfoBox
                  progress={yearProgress}
                  label="Year"
                  sx={{ marginTop: isSmallScreen ? '-60px' : '-75px' }}
                />
              </VuiBox>
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default ProductionCardRAP;