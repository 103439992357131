import { useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom'; // Import for navigation

// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// React components
import VuiBox from "components/VuiBox";
import VuiButton from "components/VuiButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// React context
import {
  useVisionUIController,
  setOpenConfigurator,
} from "context";

// Importing routes
import routes from "routes";

function Configurator() {
  const [controller, dispatch] = useVisionUIController();
  const { openConfigurator } = controller;
  const navigate = useNavigate(); // Initialize useNavigate hook
  const configuratorRef = useRef(null); // Create a reference to the configurator

  // Function to handle closing the configurator
  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  // Function to handle navigation
  const handleNavigation = (route) => {
    navigate(route);
  };

  // Detect clicks outside the configurator and close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (configuratorRef.current && !configuratorRef.current.contains(event.target)) {
        handleCloseConfigurator(); // Close the configurator if the click is outside
      }
    };

    // Add event listener to listen for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [configuratorRef]); // Empty array ensures this runs once, when the component mounts

  return (
    <ConfiguratorRoot ref={configuratorRef} variant="permanent" ownerState={{ openConfigurator }}>
      <VuiBox
        backgroundColor="black"
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <VuiBox
          component="img"
          src="bbsys.png"
          alt="Logo"
          width="25px" // Adjust size as needed
          height="35px"
        />

        <Icon
          sx={({ typography: { size, fontWeightBold }, palette: { white } }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: `${white.main} !important`,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </VuiBox>

      <Divider light />

      <VuiBox px={3} py={2} display="flex" flexDirection="column" gap={2}>
        {/* Map over the routes to dynamically create the buttons, excluding the 'sign-in' route */}
        {routes
          .filter(route => route.name !== "Sign In") // Filter out the sign-in route
          .map((route) => (
            route.type === "collapse" && (
              <VuiButton
                key={route.key}
                variant="text"
                startIcon={route.icon}
                sx={{ color: "white", justifyContent: "flex-start" }}
                onClick={() => handleNavigation(route.route)}
              >
                {route.name}
              </VuiButton>
            )
          ))}
      </VuiBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;