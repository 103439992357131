import { useEffect, useState } from "react";
import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

function SiteInformation({ siteId }) {
  const [lastUpdate, setLastUpdate] = useState("");
  const [siteAddress, setSiteAddress] = useState(null);

  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/profile?site_id=${siteId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch site data");
        }
        const data = await response.json();
        setSiteAddress(`${data.street}, ${data.address_postcode}`);
      } catch (error) {
        console.error("Error fetching site data:", error);
      }
    };

    const updateLastUpdateTime = () => {
      const currentDateTime = new Date();
      const formattedDateTime = currentDateTime.toLocaleString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      setLastUpdate(formattedDateTime);
    };

    const loadGoogleMapsScript = () => {
      const existingScript = document.getElementById("google-maps-script");
      if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBlE514dTiL8nQSdTid4KrHfhI-U4lDR80`;
        script.async = true;
        script.defer = true;
        script.id = "google-maps-script";
        script.onload = () => initializeMap();
        document.head.appendChild(script);
      } else {
        initializeMap();
      }
    };

    const initializeMap = () => {
      if (siteAddress) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: siteAddress }, (results, status) => {
          if (status === "OK") {
            const map = new window.google.maps.Map(document.getElementById("map"), {
              center: results[0].geometry.location,
              zoom: 13,
            });

            const marker = new window.google.maps.Marker({
              position: results[0].geometry.location,
              map: map,
              title: `Site Location: ${siteAddress}`,
            });

            const infowindow = new window.google.maps.InfoWindow({
              content: `Site Location: ${siteAddress}`,
            });

            marker.addListener("click", () => {
              infowindow.open(map, marker);
            });
          } else {
            console.error("Geocode was not successful for the following reason:", status);
          }
        });
      }
    };

    fetchSiteData();
    updateLastUpdateTime();
    loadGoogleMapsScript();
  }, [siteId, siteAddress]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <VuiBox display="flex" flexDirection="column" height="100%" p={2}>
        <VuiTypography
          variant="lg"
          color="white"
          fontWeight="bold"
          sx={{
            mb: 1,
            fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem" },
          }}
        >
          Site Information
        </VuiTypography>
        <VuiTypography
          variant="body2"
          color="text"
          fontWeight="regular"
          sx={{
            fontSize: {
              xs: "0.75rem",
              sm: "0.875rem",
              md: "1rem",
            },
            textAlign: "center",
            mb: 2,
          }}
        >
          Last Update: {lastUpdate}
        </VuiTypography>
        <VuiTypography
          variant="body2"
          color="text"
          fontWeight="regular"
          sx={{ textAlign: "center", mb: 2 }}
        >
          Address: {siteAddress || "Loading..."}
        </VuiTypography>
        <VuiBox
          id="map"
          sx={{
            flex: 1,
            width: "100%",
            borderRadius: "10px",
            overflow: "hidden",
            height: {
              xs: "200px",
              sm: "250px",
              md: "300px",
              lg: "350px",
              xl: "400px",
            },
          }}
        />
      </VuiBox>
    </Card>
  );
}

export default SiteInformation;