import { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import NotificationItem from "examples/Items/NotificationItem";
import VuiButton from "components/VuiButton";
import useAuth from "../../../useAuth"; // Import the custom hook

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import {
  useVisionUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import logo from "assets/images/logo.png";

function DashboardNavbar({ absolute, light, isMini }) {
  useAuth(); // This will handle token expiration check on page load

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [sites, setSites] = useState([]);
  const [userName, setUserName] = useState("Sign in");
  const location = useLocation();
  const currentSiteId = location.pathname.split("/")[2]; // Extract `site_id` from the URL

  const email = localStorage.getItem("email");
  const userToken = localStorage.getItem("token");

  if (!email) {
    console.error("User email is not available in localStorage.");
  }

  if (!userToken) {
    console.error("User token is not available in localStorage.");
  }

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!userToken || !email) {
        console.error("Missing userToken or email");
        return;
      }
  
      try {
        const response = await axios.get(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/profile`,
          {
            headers: { Authorization: `${userToken}`, "Content-Type": "application/json" },
            params: { email }, // Use the email to get user profile
          }
        );
        setUserName(response.data.first_name);
        // Store the user ID from the response
        const userId = response.data.user_id;
        fetchUserSites(userId);
      } catch (error) {
        console.error("Error fetching user profile:", error.response || error.message);
        if (error.response) {
          console.log("Response data:", error.response.data);
          console.log("Response status:", error.response.status);
        }
      }
    };
  
    const fetchUserSites = async (userId) => {
      try {
        const response = await axios.get(
          "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/sites",
          {
            headers: { Authorization: userToken },
            params: { user_id: userId }, // Use `params` to pass query parameters
          }
        );
        const fetchedSites = response.data || [];
        setSites(fetchedSites);
  
        // If no site is specified in the URL, set the first site as the default
        const currentSiteId = location.pathname.split("/")[2];
        if (!currentSiteId && fetchedSites.length > 0) {
          // Set the first site as default
          const firstSiteId = fetchedSites[0].site_id;
          // Optionally navigate to the first site (uncomment if needed)
          // history.push(`/site-dashboard/${firstSiteId}`);
        }
      } catch (error) {
        console.error("Error fetching sites:", error.message);
      }
    };
  
    fetchUserProfile();
  }, [email, userToken, location.pathname]);
  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        title={["Greenwich", "Mixing interrupted"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  // Get the current site from the URL (path parameter)
  const currentSiteName = location.pathname.split("/")[2]; // This assumes site name is in the URL after '/site-dashboard/'

  const currentSite = sites.find((site) => site.name === currentSiteName) || sites[0]; // Default to first site if no match

  const isSiteDashboardRoute = location.pathname.startsWith("/site");

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <VuiBox display="flex" alignItems="center">
          <img src={logo} alt="logo" style={{ height: "40px", marginRight: "10px" }} />
        </VuiBox>
        {isMini
          ? null
          : isSiteDashboardRoute && (
              <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
                <VuiBox display="flex" alignItems="center">
                  {sites.map((site) => (
                    <Link to={`/site-dashboard/${site.site_id}`} key={site.site_id}>
                      <VuiButton
                        variant="gradient"
                        sx={(theme) => ({
                          background:
                            currentSiteId === site.site_id.toString()
                              ? "linear-gradient(90deg, #00A9E2, #9B9C9B)"
                              : "transparent",
                          color:
                            currentSiteId === site.site_id.toString()
                              ? theme.palette.white.main
                              : theme.palette.text.main,
                          "&:hover": {
                            color: theme.palette.white.main,
                            background: "linear-gradient(90deg, #00A9E2, #9B9C9B)",
                          },
                          mx: 1,
                        })}
                      >
                        {site.name}
                      </VuiButton>
                    </Link>
                  ))}
                </VuiBox>
              </VuiBox>
            )}
        <VuiBox color={light ? "white" : "inherit"}>
          <Link to="/authentication/profile">
            <IconButton sx={navbarIconButton} size="small">
              <Icon
                sx={({ palette: { dark, white } }) => ({
                  color: light ? white.main : dark.main,
                })}
              >
                account_circle
              </Icon>
              <VuiTypography variant="button" fontWeight="medium" color={light ? "white" : "dark"}>
                {userName}
              </VuiTypography>
            </IconButton>
          </Link>
          <IconButton
            size="small"
            color="inherit"
            sx={navbarMobileMenu}
            onClick={handleMiniSidenav}
          ></IconButton>
          <IconButton
            size="small"
            color="inherit"
            sx={navbarIconButton}
            onClick={handleConfiguratorOpen}
          >
            <Icon>menu</Icon>
          </IconButton>
          <IconButton
            size="small"
            color="inherit"
            sx={navbarIconButton}
            aria-controls="notification-menu"
            aria-haspopup="true"
            variant="contained"
            onClick={handleOpenMenu}
          >
            <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
          </IconButton>
          {renderMenu()}
        </VuiBox>
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
