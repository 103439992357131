import React from 'react';
import { Card } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

const StockedMaterials = () => {
    const materials = [
        { label: 'Import', value: '0000kg' },
        { label: 'Reclaim', value: '0000kg' },
        { label: 'Fibre Red', value: '0000kg' },
    ];

    const items = [
        { label: '160/220', value: '0000kg' },
        { label: '160/220', value: '0000kg' },
        { label: '160/220', value: '0000kg' },
        { label: '160/220', value: '0000kg' },
        { label: '160/220', value: '0000kg' },
    ];

    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative', padding: 2 }}>
            <VuiBox sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <VuiTypography
                variant="lg"
                color="white"
                fontWeight="bold"
                sx={{
                    mb: 1,
                    fontSize: { xs: '1.25rem', sm: '1.5rem' },
                }}
        >
                    Stocked Materials
                </VuiTypography>

                <VuiBox sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                    {materials.map((material, index) => (
                        <VuiBox
                            key={index}
                            sx={{
                                backgroundColor: 'primary.main',
                                padding: 2,
                                borderRadius: 2,
                                flex: '1 1 100%', // Full width on small screens
                                textAlign: 'center',
                                maxWidth: 'calc(33.333% - 16px)', // 3 items per row, subtracting gap
                                boxSizing: 'border-box',
                            }}
                        >
                            <VuiTypography variant="h6" color="white">
                                {material.label}
                            </VuiTypography>
                            <VuiTypography variant="body1" color="white">
                                {material.value}
                            </VuiTypography>
                        </VuiBox>
                    ))}
                </VuiBox>

                <VuiBox sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {items.map((item, index) => (
                        <VuiBox
                            key={index}
                            sx={{
                                backgroundColor: 'secondary.main',
                                padding: 2,
                                borderRadius: 2,
                                flex: '1 1 100%', // Full width on small screens
                                textAlign: 'center',
                                maxWidth: 'calc(20% - 16px)', // 5 items per row, subtracting gap
                                boxSizing: 'border-box',
                            }}
                        >
                            <VuiTypography variant="h6" color="white">
                                {item.label}
                            </VuiTypography>
                            <VuiTypography variant="body1" color="white">
                                {item.value}
                            </VuiTypography>
                        </VuiBox>
                    ))}
                </VuiBox>
            </VuiBox>
        </Card>
    );
};

export default StockedMaterials;