import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SignOut() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    navigate("/sign-in", { replace: true });
  }, [navigate]);

  return null;
}

export default SignOut;