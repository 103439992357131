/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";

//  React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";

export default {
  columns: [
    { name: "Date Time", align: "left" },
    { name: "Asphalt", align: "center" },
    { name: "RAP Tonnage", align: "left" },
    { name: "RAP Utilisation", align: "left" },
    { name: "Annual Plant Status", align: "center" },
    { name: "Plant Idle Electricity", align: "left" },
    { name: "Electricity per Tonne", align: "center" },
    { name: "Unused Agg", align: "center" },
    { name: "Gas per Tonne", align: "center" },
    { name: "Burner Idle Fuel", align: "center" },
    { name: "Electricity Offline", align: "center" },
    { name: "Aggregate", align: "center" },
    { name: "Bitumen", align: "center" },
    { name: "Fuel", align: "center" },
    { name: "Additive", align: "center" },
    { name: "Filler", align: "center" },
    { name: "Electricity", align: "center" },
    { name: "Gas", align: "center" },
    { name: "RAP per Year", align: "left" },



  ],

  rows: [
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/05/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          370 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          130 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/05/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          370 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          130 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/05/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          370 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          130 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/05/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          370 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          130 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/05/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          370 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          130 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/05/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          370 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          130 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/05/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          370 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          130 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/05/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          330 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/06/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          330 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/06/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          330 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/06/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          330 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/06/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          330 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/06/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          330 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/06/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          330 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 92 hours
        </VuiTypography>
      ),
    },
    {
      "Date Time": (
        <VuiBox display="flex" alignItems="center">
          <VuiTypography pl="16px" color="white" variant="button" fontWeight="medium">
            18/06/2024 13:10
          </VuiTypography>
        </VuiBox>
      ),
      "Plant Idle Electricity": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6.0 kWh/year
        </VuiTypography>
      ),
      "RAP Tonnage": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 tonnes <br />
          Week: 5.1 tonnes <br />
          Month: 3.5 tonnes <br />
          Year: 4.7 tonnes
        </VuiTypography>
      ),
      Electricity: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.2 MWh
        </VuiTypography>
      ),
      Asphalt: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Day: 8.5 kT <br />
          Week: 5.3 kT <br />
          Month: 5.5 kT <br />
          Year: 4.7 kT
        </VuiTypography>
      ),
      "Unused Agg": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          6896.5 t / year
        </VuiTypography>
      ),
      "Burner Idle Fuel": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          1.0 KWh / year
        </VuiTypography>
      ),
      Gas: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          3.0 KWh
        </VuiTypography>
      ),
      "Electricity Offline": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          5.5 KWh
        </VuiTypography>
      ),
      Aggregate: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          480 kT
        </VuiTypography>
      ),
      Bitumen: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          210 kT
        </VuiTypography>
      ),
      Fuel: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      Additive: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          330 kT
        </VuiTypography>
      ),
      Filler: (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          230 kT
        </VuiTypography>
      ),
      "Annual Plant Status": (
        <VuiTypography variant="button" color="white" fontWeight="medium">
          Offline Hours: 192 hours <br />
          Production Hours: 100 hours <br />
          Idle Hours: 95 hours <br />
          Other Hours: 90 hours
        </VuiTypography>
      ),
    },
    
  ],
};
