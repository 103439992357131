import { useEffect } from "react";
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000; // Current time in seconds
          if (decodedToken.exp < currentTime) {
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            navigate("/sign-in"); // Redirect to login if token is expired
          }
        } catch (err) {
          console.error("Error decoding token", err);
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          navigate("/sign-in");
        }
      } else {
        navigate("/sign-in"); // If no token, navigate to login
      }
    };

    checkTokenExpiration(); // Run the token check on component mount
  }, [navigate]);
};

export default useAuth;