import React from 'react';

const SemiCircleProgress = ({
  strokeWidth = 12,
  strokeLinecap = "round",
  percentage = 0,
  percentageSeperator = "%",
  size = { width: 240, height: 120 },
  strokeColor = '#0075FF', // Default color
  fontStyle = { fontSize: "20px", fontFamily: "Arial", fontWeight: "bold", fill: "white" }, // White text
  hasBackground = true,
  bgStrokeColor = `${strokeColor}33`,
}) => {
  const radius = 50 - strokeWidth / 2;
  const circumference = 1.1 * Math.PI * radius;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;
  const bgStrokeDashoffset = circumference - 1 * circumference;
  const pathDescription = "M5,64 a1,1 0 0,1 90,0";

  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {hasBackground && (
        <path
          d={pathDescription}
          style={{
            stroke: bgStrokeColor,
            strokeLinecap: strokeLinecap,
            strokeDasharray: `${circumference}`,
            strokeDashoffset: `${bgStrokeDashoffset}`,
            strokeWidth: `${strokeWidth}`,
            transition: "stroke-dashoffset 0.35s",
          }}
          fill="none"
        />
      )}
      <path
        d={pathDescription}
        style={{
          stroke: strokeColor, // Use the strokeColor directly
          strokeLinecap: strokeLinecap,
          strokeDasharray: `${circumference}`,
          strokeDashoffset: `${strokeDashoffset}`,
          strokeWidth: `${strokeWidth}`,
          transition: "stroke-dashoffset 0.35s",
        }}
        fill="none"
      />
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        style={fontStyle} // White text style
      >
        {percentage}
        {percentageSeperator || "%"}
      </text>

    </svg>
  );
};

export default SemiCircleProgress;