export const groupBarChartOptions = {
    chart: {
      type: "bar",
      height: "100%",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
      },
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      labels: {
        style: {
          colors: "#fff", // White text for axis labels
        },
      },
    },
    yaxis: {
      ticks: {
        display: false,
      },
      show: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: false, // Hide Y-axis grid lines
        },
      },
      padding: { left: 0, right: -10, top: 0, bottom: 0 },
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#2C7DA0", "#5A6ACF", "#89C2D9", "#BBD0FF"], // Colors for Asphalt, RAP, Fuel, Virgin
  
    // Disable data labels
    dataLabels: {
      enabled: false,
    },
  
    tooltip: {
        enabled: true,
        shared: true, // This will show all group values together
        intersect: false, // This allows the tooltip to be shown even when hovering over a space between bars
        formatter: function (series) {
          let tooltipContent = `Month: ${series[0].data[0].category}<br>`;
          series.forEach((s) => {
            tooltipContent += `${s.seriesName}: ${s.data[0].value}<br>`;
          });
          return tooltipContent;
        },
      },
    };