import React from "react";
import { Card, Grid } from "@mui/material";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import SemiCircleProgress from "./SemiCircleProgress";
import CircularProgressWithCircle from "./CircularProgressWithCircle";
import PieChart from "./PieChart";
import ReactApexChart from "react-apexcharts";
import { groupBarChartData } from "../../data/groupBarChartData";
import { groupBarChartOptions } from "../../data/groupBarChartOptions";

// Sample data
const sampleData = {
  offlineHours: 20,
  productionHours: 40,
  idleHours: 30,
  breakdownHours: 10,
  electricity: 5.5,
  fuel: 5,
  unusedAgg: 8935.3,
};

const DynamicSiteCard = ({ site }) => {
  const electricityPercentage = (sampleData.electricity / 10) * 100;
  const fuelPercentage = (sampleData.fuel / 10) * 100;
  const unusedAggPercentage = (sampleData.unusedAgg / 10000) * 100;

  return (
    <Card>
      <VuiBox p={1}>
        {/* Row 1 */}
        <Grid container spacing={0} sx={{ marginBottom: 0 }}>
          <Grid item xs={12}>
            <VuiTypography variant="h6" color="white">
              {site.name}
            </VuiTypography>
          </Grid>
          <Grid container item xs={12} spacing={1} sx={{ marginBottom: 0 }}>
            {/* Asphalt */}
            <Grid item xs={3}>
              <VuiTypography variant="body2" style={{ color: "#2C7DA0" }} align="center">
                Asphalt
              </VuiTypography>
              <VuiTypography color="white" align="center">
                {site.avgAsphalt}{" "}
                <VuiTypography variant="caption" color="#747474" display="inline">
                  t
                </VuiTypography>{" "}
              </VuiTypography>
            </Grid>
            {/* RAP */}
            <Grid item xs={3}>
              <VuiTypography variant="body2" style={{ color: "#5A6ACF" }} align="center">
                RAP
              </VuiTypography>
              <VuiTypography color="white" align="center">
                {site.avgRap}{" "}
                <VuiTypography variant="caption" color="#747474" display="inline">
                  t
                </VuiTypography>{" "}
              </VuiTypography>
            </Grid>
            {/* Fuel */}
            <Grid item xs={3}>
              <VuiTypography variant="body2" style={{ color: "#89C2D9" }} align="center">
                Fuel
              </VuiTypography>
              <VuiTypography color="white" align="center">
                {site.avgFuel}{" "}
                <VuiTypography variant="caption" color="#747474" display="inline">
                  L
                </VuiTypography>
              </VuiTypography>
            </Grid>
            {/* Virgin */}
            <Grid item xs={3}>
              <VuiTypography variant="body2" style={{ color: "#BBD0FF" }} align="center">
                Virgin
              </VuiTypography>
              <VuiTypography color="white" align="center">
                {site.avgVirgin}{" "}
                <VuiTypography variant="caption" color="#747474" display="inline">
                  t
                </VuiTypography>{" "}
              </VuiTypography>
            </Grid>
          </Grid>
        </Grid>

        {/* Row 2 */}
        <Grid container spacing={0} sx={{ marginBottom: 0 }}>
          <Grid item xs={2} sm={4} md={4}>
            <VuiTypography variant="h6" color="white">
              Electricity
            </VuiTypography>
            <VuiBox display="flex" justifyContent="flex-start">
              <SemiCircleProgress
                strokeWidth={6}
                percentage={electricityPercentage}
                strokeColor="#CAB773"
                size={{ width: 180, height: 90 }}
                value={`${sampleData.electricity} kWh`}
              />
            </VuiBox>

            <VuiTypography variant="h6" color="white">
              Fuel
            </VuiTypography>
            <VuiBox display="flex" justifyContent="flex-start">
              <SemiCircleProgress
                strokeWidth={6}
                percentage={fuelPercentage}
                strokeColor="#C63C51"
                size={{ width: 180, height: 90 }}
                value={`${sampleData.fuel} L`}
              />
            </VuiBox>
          </Grid>
          <Grid item xs={2} sm={4} md={4} position="relative">
            {/* Circle progress bars arranged with electricity and fuel aligned vertically */}
            <VuiBox position="relative" height={150} width={150}>
              <VuiBox position="absolute" top={0} left="30%" transform="translateX(-50%)">
                <CircularProgressWithCircle
                  progress={fuelPercentage}
                  title="Fuel"
                  color="#F0A8D0"
                  size={100}
                />
              </VuiBox>

              <VuiBox position="absolute" top={0} left="0">
                <CircularProgressWithCircle
                  progress={electricityPercentage}
                  title="Electricity"
                  color="#C63CA8"
                  size={80}
                />
              </VuiBox>

              <VuiBox position="absolute" top="60%" left="10%" right="0">
                <CircularProgressWithCircle
                  progress={unusedAggPercentage}
                  title="Unused Agg."
                  color="#5E5CE6"
                  size={130}
                />
              </VuiBox>
            </VuiBox>
          </Grid>
          {/* Pie Chart */}
          <Grid item xs={2} sm={4} md={4} sx={{ height: "50%" }}>
            <PieChart />
          </Grid>
        </Grid>

        {/* Row 3: Grouped Bar Chart */}
        <Grid container spacing={0} sx={{ marginTop: 0 }}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <ReactApexChart
              options={groupBarChartOptions}
              series={groupBarChartData.series}
              type="bar"
            />
          </Grid>
        </Grid>
      </VuiBox>
    </Card>
  );
};

export default DynamicSiteCard;