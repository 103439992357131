import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Card } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from 'chart.js';
import colors from 'assets/theme/base/colors';

// Register chart components
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

const AnnualPlantStatus = () => {
  const { gradients } = colors;
  const { cardContent } = gradients;

  const data = {
    labels: ['Offline Hours', 'Production Hours', 'Idle Hours', 'Other Hours'],
    datasets: [{
      label: 'Hours',
      data: [192, 100, 95, 92],
      backgroundColor: ['#1F316F', '#0077C0', '#BDC3FF', '#C7EEFF'],
      borderColor: '#ffffff',
      borderWidth: 1,
    }],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = context.raw || 0;
            return `${label}: ${value} hours`;
          }
        }
      }
    }
  };

  // Media queries for responsiveness
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <Card sx={{ height: '100%', padding: 2 }}>
      <VuiBox 
        display="flex" 
        flexDirection="column" 
        justifyContent="space-between"
        height="100%" 
        p={2}
      >
        <VuiTypography
          variant="lg"
          color="white"
          fontWeight="bold"
          sx={{
            mb: 1,
            fontSize: isSmallScreen ? '1.25rem' : isMediumScreen ? '1.5rem' : '1.75rem',
          }}
        >
          Annual Plant Status
        </VuiTypography>

        <VuiBox
          display="flex"
          flexDirection={isSmallScreen ? 'column' : 'row'}  // Stack on smaller screens, horizontal on larger screens
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%', flexGrow: 1 }}  // Ensure the chart and text fill the available space
          mb={4}
        >
          <VuiBox
            sx={{ 
              flex: 1, 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              position: 'relative',
              width: isSmallScreen ? '100%' : isMediumScreen ? '50%' : '40%',  // Responsive width based on screen size
              height: isSmallScreen ? '250px' : isMediumScreen ? '300px' : '350px',  // Adjust height for screens
            }}
          >
            <Pie data={data} options={options} />
          </VuiBox>

          <VuiBox
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: isSmallScreen ? 'center' : 'flex-start',  // Center on small screens, left-align on larger screens
              p: 2,
              height: '100%', 
            }}
          >
            {data.labels.map((label, index) => (
              <VuiBox display="flex" alignItems="center" mb={1} key={index}>
                <VuiBox
                  sx={{
                    width: '15px',
                    height: '15px',
                    borderRadius: '50%',
                    backgroundColor: data.datasets[0].backgroundColor[index],
                    mr: 2,
                  }}
                />
                <VuiTypography variant="caption" color="text" sx={{ fontSize: isSmallScreen ? '12px' : isMediumScreen ? '14px' : '16px' }}>
                  {label}: {data.datasets[0].data[index]} hours
                </VuiTypography>
              </VuiBox>
            ))}
          </VuiBox>
        </VuiBox>
      </VuiBox>
    </Card>
  );
};

export default AnnualPlantStatus;