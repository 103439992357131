import React, { useState, useEffect } from "react";
import {
  Card,
  useMediaQuery,
  useTheme,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import SemiCircleProgress from "./SemiCircleProgress";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import VuiButton from "components/VuiButton";

const ProductionCardAsp = ({
  title,
  dayProgress,
  weekProgress,
  monthProgress,
  yearProgress,
  color,
  unit,
  maxDayValue = 10,
  maxWeekValue = 10,
  maxMonthValue = 10,
  maxYearValue = 10,
  site_id, // Add site_id prop for API interaction
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [siteData, setSiteData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [annualTotal, setAnnualTotal] = useState(0);
  const [workDaysPerWeek, setWorkDaysPerWeek] = useState(0);

  // Fetch site data from the API
  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const response = await fetch(
          `https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/profile?site_id=${site_id}`
        );
        const data = await response.json();
        setSiteData(data);
        setAnnualTotal(data.annual_total || 0);
        setWorkDaysPerWeek(data.work_days_per_week || 0);
      } catch (error) {
        console.error("Error fetching site data:", error.message);
      }
    };

    fetchSiteData();
  }, [site_id]);

  // Calculate target values
  const calculateTargetValues = () => {
    const weekTarget = annualTotal / 52;
    const monthTarget = annualTotal / 12;
    const dayTarget = annualTotal / (52 * workDaysPerWeek);
    return { weekTarget, monthTarget, dayTarget };
  };

  // Handle Open and Close of the Edit Dialog
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  // Handle Update
  const handleUpdate = async () => {
    const payload = {
      site_id: site_id,
      annual_total: annualTotal,
      work_days_per_week: workDaysPerWeek,
    };

    try {
      const response = await fetch(
        "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/site/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      if (response.ok) {
        console.log("Data updated successfully");
        setOpenDialog(false);
      } else {
        console.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const { gradients } = colors;
  const { cardContent } = gradients;

  const InfoBox = ({ progress, label, sx }) => (
    <VuiBox
      sx={{
        width: isSmallScreen ? "150px" : "250px",
        padding: isSmallScreen ? "10px 14px" : "16px 20px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        borderRadius: "20px",
        background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
        ...sx,
      }}
    >
      <VuiTypography color="text" variant="caption" display="inline-block">
        0%
      </VuiTypography>
      <VuiBox
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ minWidth: isSmallScreen ? "50px" : "70px" }}
      >
        <VuiTypography color="white" variant="h3" sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}>
          {progress} {unit}
        </VuiTypography>
        <VuiTypography color="text" variant="caption" display="inline-block">
          {label}
        </VuiTypography>
      </VuiBox>
      <VuiTypography color="text" variant="caption" display="inline-block">
        100%
      </VuiTypography>
    </VuiBox>
  );

  const { weekTarget, monthTarget, dayTarget } = calculateTargetValues();

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 1,
        paddingBottom: 0,
      }}
    >
      <VuiBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{ paddingBottom: 0 }}
      >
        <VuiTypography
          variant="lg"
          color="white"
          fontWeight="bold"
          sx={{
            mb: 1,
            fontSize: isSmallScreen ? "1.25rem" : isMediumScreen ? "1.5rem" : "1.75rem",
          }}
        >
          {title}
        </VuiTypography>
        <IconButton onClick={handleOpenDialog}>
          <EditIcon sx={{ color: 'white !important' }} />
        </IconButton>
      </VuiBox>

      <VuiBox
        display="flex"
        flexDirection={isSmallScreen ? "column" : "row"}
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        gap={isSmallScreen ? 2 : 3}
        sx={{ paddingBottom: 0 }}
      >
        {/* Day Progress */}
        <VuiBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flex: "1 0 auto",
            maxWidth: isSmallScreen ? "250px" : "350px",
          }}
        >
          <VuiBox sx={{ display: "inline-flex" }}>
            <SemiCircleProgress
              percentage={Math.ceil((dayProgress / dayTarget) * 100)}
              size={{
                width: isSmallScreen ? 220 : 270,
                height: isSmallScreen ? 220 : 270,
              }}
              strokeWidth={10}
              strokeColor={color}
            />
          </VuiBox>
          <InfoBox
            progress={dayProgress}
            label="Day"
            sx={{
              minWidth: isSmallScreen ? "200px" : "320px",
              marginTop: isSmallScreen ? "-60px" : "-90px",
            }}
          />
        </VuiBox>

        {/* Week, Month, Year Progress */}
        <VuiBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "center",
            paddingBottom: isSmallScreen ? "30px" : "50px",
          }}
        >
          {/* Week Progress */}
          <VuiBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexGrow: 1,
              width: "100%",
            }}
          >
            <SemiCircleProgress
              percentage={Math.ceil((weekProgress / weekTarget) * 100)}
              size={{ width: isSmallScreen ? 150 : 180, height: isSmallScreen ? 150 : 180 }}
              strokeWidth={10}
              strokeColor={color}
            />
            <InfoBox
              progress={weekProgress}
              label="Week"
              sx={{ marginTop: isSmallScreen ? "-60px" : "-75px" }}
            />
          </VuiBox>

          {/* Month and Year Progress */}
          <VuiBox
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: isSmallScreen ? "10px" : "16px",
              width: "100%",
              flexShrink: 0,
            }}
          >
            <VuiBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <SemiCircleProgress
                percentage={Math.ceil((monthProgress / monthTarget) * 100)}
                size={{ width: isSmallScreen ? 150 : 180, height: isSmallScreen ? 150 : 180 }}
                strokeWidth={10}
                strokeColor={color}
              />
              <InfoBox
                progress={monthProgress}
                label="Month"
                sx={{ marginTop: isSmallScreen ? "-60px" : "-75px" }}
              />
            </VuiBox>
            <VuiBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <SemiCircleProgress
                percentage={Math.ceil((yearProgress / annualTotal) * 100)}
                size={{ width: isSmallScreen ? 150 : 180, height: isSmallScreen ? 150 : 180 }}
                strokeWidth={10}
                strokeColor={color}
              />
              <InfoBox
                progress={yearProgress}
                label="Year"
                sx={{ marginTop: isSmallScreen ? "-60px" : "-75px" }}
              />
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>

      {/* Edit Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Edit Production Targets</DialogTitle>
        <DialogContent>
          <TextField
            label="Annual Total"
            type="number"
            fullWidth
            value={annualTotal}
            onChange={(e) => setAnnualTotal(Number(e.target.value))}
            sx={{ marginBottom: 2, marginTop: 2 }}
          />
          <TextField
            label="Work Days per Week"
            type="number"
            fullWidth
            value={workDaysPerWeek}
            onChange={(e) => setWorkDaysPerWeek(Number(e.target.value))}
            sx={{ marginBottom: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <VuiButton onClick={handleCloseDialog} color="secondary" >Cancel</VuiButton>
          <VuiButton onClick={handleUpdate} color="primary">
            Update
          </VuiButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ProductionCardAsp;
