import { useState, useEffect } from "react";
import { Router, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import routes from "routes";
import { useVisionUIController, setOpenConfigurator } from "context";
import SiteDashboard from "layouts/site-dashboard"; // import your site dashboard component
import axios from "axios"; // Ensure axios is available for fetching user sites

export default function App() {
  const [controller, dispatch] = useVisionUIController();
  const { direction, layout, openConfigurator } = controller;
  const { pathname } = useLocation();
  
  // State to store the user's sites
  const [sites, setSites] = useState([]);
  const userToken = localStorage.getItem("token");

  // Fetch the user's sites when the app loads
  useEffect(() => {
    const fetchUserSites = async () => {
      try {
        const response = await axios.get(
          "https://zghl83gvne.execute-api.eu-west-2.amazonaws.com/dev/user/sites", 
          {
            headers: { Authorization: `${userToken}`, "Content-Type": "application/json" },
            params: { user_id: localStorage.getItem("user_id") },
          }
        );
        setSites(response.data); // Set the user's sites in the state
      } catch (error) {
        console.error("Error fetching sites on log in:", error);
      }
    };

    fetchUserSites();
  }, []);

  // Get the first site id as the default site
  const defaultSiteId = sites.length > 0 ? sites[0].site_id : null;

  // Redirect to the default site dashboard if no site_id is present in the URL
  useEffect(() => {
    if (defaultSiteId && pathname === "/site-dashboard") {
      window.location.href = `/site-dashboard/${defaultSiteId}`;
    }
  }, [defaultSiteId, pathname]);

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (routes) => {
    return routes.map((route) => {
      if (route.type === "collapse") {
        return (
          <Route
            key={route.key}
            path={route.route}
            element={<route.component />}
          />
        );
      }
      return null; // Handle other types as needed
    });
  };

  const configsButton = (
    <VuiBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="info"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="white"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </VuiBox>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "site-dashboard" && <Configurator />}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        {/* Wrap the Navigate component inside a Route */}
        <Route path="*" element={<Navigate to="/site-dashboard" />} />
        <Route path="/site-dashboard/:site_id" element={<SiteDashboard />} />
      </Routes>
    </ThemeProvider>
  );
}